import {Controller} from "stimulus"
import $ from 'jquery';
import {checkSessionVar, setSessionVar} from "./sessionStorage_controller";
import {set_locale} from "./localize_controller";
// import lightGallery from "lightgallery";
// import lgZoom from "lightgallery/plugins/zoom";
// import lgThumbnail from "lightgallery/plugins/thumbnail";
import {Fancybox} from "@fancyapps/ui";

export let check_time_reservation = (behavior) => {
    let reservation_div = $('#reservation');
    let timeOut = 1000;

    let interval = setInterval(function () {
        let date_future = new Date(parseInt(sessionStorage.getItem('reservation_time')));
        let date_now = new Date();

        let seconds = Math.floor((date_future - (date_now)) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        hours = hours - (days * 24);
        minutes = minutes - (days * 24 * 60) - (hours * 60);
        seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);

        if(minutes + seconds > 0 && minutes < 8 && sessionStorage.getItem('has_reservation') === 'true'){
            $('.time_remaining').text(`${minutes} ${I18n.t('global.time.minutes')} - ${seconds} ${I18n.t('global.time.seconds')}`);

            if(behavior === 'normal'){
                if ((minutes % 2) === 0 && seconds > 50) {

                    reservation_div.addClass('show_reservation');
                    reservation_div.removeClass('hidde_reservation');
                }
            }

        }else{
            $('.time_remaining').text(`0 ${I18n.t('global.time.minutes')} - 0 ${I18n.t('global.time.seconds')}`);
            clearInterval(interval);
            if(behavior === 'normal'){
                reservation_div.addClass('hidde_reservation');
                reservation_div.removeClass('show_reservation');
            }


            if(sessionStorage.getItem('has_reservation') === 'true'){
                setSessionVar('', '', 'clear', false).then(
                    removed =>{
                        set_locale().then(
                            locale => {
                                location.href = `/${locale.locale}`;
                            }
                        )
                    }
                )
            }

        }

    }, timeOut);
}

export default class extends Controller {

    connect() {
        $('.alert').fadeIn("1000").delay("3000").fadeOut();
        $('.alert_error').fadeIn("1000").delay("4000").fadeOut();

        Fancybox.bind('[data-fancybox="gallery"]', {
            // Your custom options
        });
    }

    hideToggle(e) {
        let div = $(`${e.target.dataset.target}`);
        let icon_down = $(`${e.target.dataset.down}`);
        let icon_up = $(`${e.target.dataset.up}`);

        div.toggleClass('active');
        icon_down.toggleClass('active');
        icon_up.toggleClass('active');
    }
}